<!--HOME主页-->
<template>
  <section class='container'>
    <div class='header'>
<!--      这是点击把标签收起-->
      <div style="display: inline-block">
        <i @click.prevent='collapse' class='tools' :class='collapsed ? "el-icon-s-unfold" : "el-icon-s-fold"' />
<!--        路由链接到dashboard  监管地图-->
        <router-link style="text-decoration:none;color:#fff" to="/dashboard">{{(user.Info?user.Info.org:'明瑞科技')+'油烟管理智慧平台'}}</router-link>
      </div>
      <div class='userinfo'>
        <!-- <i class="fa fa-id-card-o" />
        {{roleFormatter(role)}} -->
        <i class='fa fa-user-circle-o'></i>
        {{ user.Name }}
        <el-dropdown @command="handleCommand">
          <span class="el-dropdown-link">
            <i class='fa fa-cog' />
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="1">个人信息</el-dropdown-item>
            <el-dropdown-item :command="2">修改密码</el-dropdown-item>
            <el-dropdown-item :command="3">数据重载</el-dropdown-item>
<!--       divided添加分割线     -->
            <el-dropdown-item divided :command="0">退出系统</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="main">
      <aside :class='collapsed ? "menu-collapsed" : "menu-expanded"'>
        <!--导航菜单-折叠前-->
        <template v-if='!collapsed'>
<!--      unique-opened保持一个子页面展开    -->
          <el-menu :default-active='$route.path' unique-opened router>
            <template v-for="(item, index) in $router.options.routes">
              <template v-if="!item.hidden">
                <el-menu-item v-if="item.children==undefined" :index="item.path" :key="index">
                  <i :class='item.Icon' /> {{ item.Name }}
                </el-menu-item>
                <el-submenu :index="index+''" v-else :key="index">
                  <template slot="title">
                    <i :class='item.Icon' /> {{ item.Name }}
                  </template>
                  <el-menu-item v-for="child in item.children" :index="child.path" :key="child.Id">
                    <span v-if="!child.hidden">
                      {{ child.Name }}
                    </span>
                  </el-menu-item>
                </el-submenu>
              </template>
            </template>
          </el-menu>
        </template>
        <!--导航菜单-折叠后-->
        <ul class='el-menu collapsed' v-else ref='menuCollapsed'>
          <li v-for='(item, index) in $router.options.routes' class='el-submenu item' :key='index'>
            <template v-if='!item.hidden'>
              <template v-if='item.children===undefined'>
                <li class='el-submenu'>
                  <div class='el-submenu__title' :class="{'is-active':$route.path===item.path}" @click='$router.push(item.path)'>
                    <i :class='item.Icon' />
                  </div>
                </li>
              </template>
              <template v-else>
                <div class='el-submenu__title' @mouseover='showMenu(index,true)' @mouseout='showMenu(index,false)'>
                  <i :class='item.Icon' />
                </div>
                <ul class='el-menu submenu' :class="'submenu-hook-' + index" @mouseover='showMenu(index,true)' @mouseout='showMenu(index,false)'>
                  <li v-for='(child, index) in item.children' class='el-menu-item' :class="{'is-active':$route.path===child.path}" @click='$router.push(child.path)' :key='index'>
                    <template v-if='!child.hidden'>
                      {{ child.Name }}
                    </template>
                  </li>
                </ul>
              </template>
            </template>
          </li>
        </ul>
      </aside>
      <section class='content-container'>
        <!-- <breadcrumb /> -->
        <transition name='fade' mode='out-in'>
          <router-view></router-view>
        </transition>
      </section>
    </div>
    <el-dialog :title="isInfo?'个人信息':'修改密码'" :visible.sync="showDialog" @close="handlerClose">
      <template v-if="isInfo">
        <el-form size='mini' :model='user' :rules='formRule' label-width="100px" ref='userForm'>
          <el-form-item prop='Name' label="姓名" >
            <el-input @change="hasChange" v-model="user.Name" :disabled='!editable'></el-input>
          </el-form-item>
          <el-form-item label="创建日期">
            <el-input v-model="user.CreateAt" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item prop='Mobile' label="手机号码">
            <el-input @change="hasChange" v-model="user.Mobile" :disabled='!editable'></el-input>
          </el-form-item>
          <!-- <el-form-item label="公众参与码">
            <el-avatar shape="square" :size="120" fit="fill" :src="user.publicQrcode"></el-avatar>
            <el-button type="text" icon="el-icon-download" style="margin-left: 10px;" @click="downloadPublicQrcode(user.publicQrcode)">下载</el-button>
          </el-form-item> -->
        </el-form>
        <div slot="footer">
          <template v-if='editable'>
            <el-button type="primary" size="mini" @click.native="handleSubmit">提交</el-button>
          </template>
          <template v-else>
            <el-button type="primary" size="mini" @click.native="editable=!editable">修改</el-button>
          </template>
        </div>
      </template>
      <template v-else>
        <el-form :model='formData' :rules='formRule' label-width='128px' ref='formData' size='mini' >
          <el-form-item prop='p0' label='原密码' >
            <el-input @change="hasChange" size='mini' type="password" v-model.trim='formData.p0' ></el-input>
          </el-form-item>
          <el-form-item prop='p' label='新密码' >
            <el-input @change="hasChange" size='mini' type="password" v-model.trim='formData.p' ></el-input>
          </el-form-item>
          <el-form-item prop='p1' label='再次输入新密码' >
            <el-input @change="hasChange" size='mini' type="password" v-model.trim='formData.p1' ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button type="primary" size="mini" @click.native="handleChangePasswd">提交</el-button>
        </div>
      </template>
    </el-dialog>
  </section>
</template>


<script>
// import { mapState } from 'vuex'
import { stg, getUserInfo, dateFormater, loadData, removeToken, roleFormatter, downloadImage } from '@/util/index'
import { validatePass, mobileValidator } from '@/util/validate'
// import Breadcrumb from '@/component/Breadcrumb'

export default {
  // components: {
  //   Breadcrumb
  // },
  data() {
    return {
      roleFormatter,
      changeState: false,
      user: {},
      sysName: '明瑞',
      role: '',
      selectedArea: '',
      collapsed: false,
      showDialog: false,
      isInfo: true,
      editable: false,
      formData: {
        p0: '',
        p: '',
        p1: ''
      },
      formRule: {
        p0: [{ required: true, validator: validatePass, trigger: 'blur' }],
        p: [{ required: true, validator: validatePass, trigger: 'blur' }],
        p1: [
          { required: true, validator: validatePass, trigger: 'blur' },
          { validator: this.validatePassMatch, trigger: 'blur' }
        ],
        Mobile: [{ required: true, validator: mobileValidator, trigger: 'blur' }]
      }
    }
  },
  computed: {
    // ...mapState(['props', 'areaTree'])
  },
  created() {
    this.user = getUserInfo()
    this.user.CreateAt = dateFormater(this.user.CreateAt, false)
    this.user.publicQrcode = 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1598604937201&di=4f0f9b6ba8ca5c8800a1d20b0d86b8ea&imgtype=0&src=http%3A%2F%2Fa3.att.hudong.com%2F14%2F75%2F01300000164186121366756803686.jpg'
    this.role = stg().getItem('role')
    // if (JSON.parse(stg().getItem('user')).PlainPassword) {
    //   this.showDialog = true
    //   this.isInfo = false
    // }
  },
  methods: {
    /**
     * @description 下载公众参与码
     */
    downloadPublicQrcode(url) {
      downloadImage(url, '公众参与码')
    },
    hasChange() {
      this.changeState = true
    },
    dateFormat(r, c) {
      return dateFormater(r[c.property], false)
    },
    handleSubmit() {
      if (!this.changeState) {
        this.editable = false
        return
      }
      this.$refs.userForm.validate(valid => {
        if (valid) {
          this.changeState = false
          this.$get('admin/usermodify', { name: this.user.Name, mobile: this.user.Mobile }).then(res => {
            if (res) {
              this.editable = false
              stg().setItem('user', JSON.stringify(this.user))
            }
          })
        }
      })
    },
    handleSearch(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    handlerClose() {
      this.editable = false
      if (this.$refs['userForm']) {
        this.$refs['userForm'].resetFields()
      }
      // this.showDialog = false
    },
    handleNodeClick(node) {
      this.selectedArea = node.Name
    },
    validatePassMatch(_, value, callback) {
      if (value !== this.formData.p) {
        callback(new Error('两次输入密码不一致'))
      } else if (this.formData.p0 === value) {
        this.$message.warning(`新密码与原密码相同`)
        return
      } else {
        callback()
      }
    },
    handleChangePasswd() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          this.$get('admin/changepasswd', {
            p0: this.formData.p0,
            p1: this.formData.p1
          }).then(res => {
            if (res) {
              this.showDialog = false
              this.$refs['formData'].resetFields()
            }
          })
        }
      })
    },
//点击菜单，传过来个common，然后根据common判断执行的操作
    handleCommand(c) {
      switch (c) {
        case 1:
          this.showDialog = true
          this.isInfo = true
          break
        case 2:
          this.showDialog = true
          this.isInfo = false
          break
        case 3:
          loadData(this)
          break
        default:
          this.$confirm('确认退出吗?', '提示', {}).then(() => {
            // this.$post('logoutAction').then(data => {})
            removeToken()
            this.$store.commit('clear')
            this.$router.push({
              name: 'login'
            })
          }).catch(() => {})
      }
    },
    // 折叠导航栏
    collapse() {
      this.collapsed = !this.collapsed
    },
    showMenu(i, status) {
      this.$refs.menuCollapsed.getElementsByClassName(
        'submenu-hook-' + i
      )[0].style.display = status ? 'block' : 'none'
    }
  }
}
</script>

<style scoped lang='scss'>
.container {
  .header {
    height: 60px;
    line-height: 60px;
    background: #8896B3;
    color: #fff;
    width: 100%;
    .userinfo {
      text-align: right;
      padding-right: 25px;
      float: right;
      .el-dropdown-link {
        .fa-cog{
          cursor: pointer;
          color: #fff;
          font-size: 18px;
        }
      }
    }
    .tools {
      padding: 0px 23px;
      width: 14px;
      height: 60px;
      line-height: 60px;
      cursor: pointer;
    }
  }
  .main {
    display: flex;
    position: absolute;
    top: 60px;
    bottom: 0px;
    overflow: hidden;
    width: 100%;
    aside {
      .el-menu {
        // height: 100%;
        min-height: 100%;
        background-color: #D9DEE4;
        .el-submenu__title {
          padding: 0 24px;
        }
      }
      .collapsed {
        width: 60px;
        .item {
          position: relative;
        }
        .submenu {
          position: absolute;
          top: 0px;
          left: 60px;
          z-index: 99999;
          height: auto;
          display: none;
        }
      }
      &.menu-collapsed {
        flex: 0 0 60px;
      }
      &.menu-expanded {
        flex: 0 0 200px;
        overflow-y: auto;
      }
    }
    .content-container {
      flex-shrink: 1;
      flex-grow: 0;
      flex-basis: 100%;
      padding: 15px;
      overflow-y: auto;
      background-color: #F5F7FA;
    }
  }
  .copyright {
    position: absolute;
    bottom: 0;
}
}
</style>
